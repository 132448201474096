<template>
  <div class="section-h100">
    <div class="background bg-signupscreen"></div>
    <div class="container-fluid">
      <div class="box-signin animate__animated animate__slideInUp animate__delay-0.7s">
        <h2 class="box-header">{{ $t("Signup.resetpass_title") }}</h2>
        <div class="box-content">
          <form class="form form-signup">
            <div class="inner">
              <div class="input-block" @click="getfocus('password')" id="password">
                <span class="input-text">{{ $t("Signup.password") }}</span>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control int2"
                  v-model="signupData.password"
                  @keyup.enter="confirmChangePasswordFunc"
                />
                <span class="icons icon-eye-close field-icon toggle-password">
                  <v-btn icon small @click="showPassword = !showPassword">
                    <v-icon>
                      {{
                      !showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline"
                      }}
                    </v-icon>
                  </v-btn>
                </span>
              </div>
              <div class="input-block" @click="getfocus('comfirmpass')" id="comfirmpass">
                <span class="input-text">{{ $t("Signup.cfpassword") }}</span>
                <input
                  :type="showCfPassword ? 'text' : 'password'"
                  class="form-control int3"
                  v-model="signupData.cfpassword"
                  @keyup.enter="confirmChangePasswordFunc"
                />
                <span class="icons icon-eye-close field-icon toggle-password">
                  <v-btn icon small @click="showCfPassword = !showCfPassword">
                    <v-icon>
                      {{
                      !showCfPassword
                      ? "mdi-eye-off-outline"
                      : "mdi-eye-outline"
                      }}
                    </v-icon>
                  </v-btn>
                </span>
              </div>
            </div>

            <div class="buttons">
              <button
                type="button"
                class="btn btn-lg w-100 gl-bt hover_btn"
                @click="confirmChangePasswordFunc()"
              >
                <span>{{ $t("SigninWeekend.dialog_confirm") }}</span>
              </button>
            </div>
          </form>

          <hr />
          <p class="m-0">
            {{ $t("Signup.by") }}
            <a @click="gosignin">{{ $t("Signup.gotologin") }}</a>
            {{ $t("Signup.or") }}
            <a
              @click="gosignup"
              class="mr-2"
            >{{ $t("Signup.createaccount") }}</a>
            <br class="d-block d-md-none" />
            {{ $t("Signup.policy2") }}
            <br />
            <a @click="gotogeneralterms()">{{ $t("Signup.policy3") }}</a>
            {{ $t("Signup.policy4") }}
            <a
              @click="gotoserviceterms()"
            >{{ $t("Signup.policy5") }}</a>
            {{ $t("Signup.policy6") }}
          </p>
          <hr />
          <p class="m-0 p-1 f12">{{ $t("Signup.sign") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "ResetPasswordScreen",
  metaInfo: {
    title: "Reset Password",
    titleTemplate: "%s | Aigent"
  },
  data: () => ({
    loading: false,
    signupData: {
      email: "",
      password: "",
      cfpassword: ""
    },
    showPassword: false,
    showCfPassword: false,
    rules: {
      required: value => !!value || "จำเป็น",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "อีเมลไม่ถูกต้อง";
      }
    },
    value_element: "",
    passwordToken: ""
  }),
  created() {
    const self = this;
    self.passwordToken = self.$route.params.passwordToken;
  },
  mounted() {
    this.colorHover();
    EventBus.$on("gotoSignin", this.initSignup);
  },
  computed: {},
  watched() {
    this.value_element;
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus");
        element.classList.add("focus");
        this.value_element = val;
      }
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    gosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    gosignup() {
      var item = { link: "signup", name: "SignupScreen" };
      EventBus.$emit("changePathname", item);
    },
    gotogeneralterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/general`);
    },
    gotoserviceterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/privacy`);
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    async confirmChangePasswordFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      if (self.signupData?.password && self.signupData?.cfpassword) {
        if (
          self.signupData.password.length < 8 ||
          self.signupData.cfpassword.length < 8
        ) {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.passmore"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (self.signupData.password !== self.signupData.cfpassword) {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.mismatchpass"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (self.signupData.password == self.signupData.cfpassword) {
          const obj = {
            password_token: self.passwordToken,
            password_new: self.signupData.password,
            password_confirm: self.signupData.cfpassword
          };
          try {
            const res = await self.axios.post(
              process.env.VUE_APP_API + "/set_new_password",
              obj
            );
            // console.log(res)
            if (res.status == 200) {
              EventBus.$emit("endloading");
              swal(
                self.$t("Alert.success_title"),
                self.$t("Alert.save_success"),
                self.$t("Alert.success_label"),
                {
                  button: false,
                  timer: 3000
                }
              ).then(() => self.gosignin());
            }
          } catch (error) {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.error_title"),
              error?.response?.data?.message == "user not found" ||
                error?.response?.data?.message == "invalid value"
                ? self.$t("Alert.signupplease")
                : error?.response?.data?.message || self.$t("Alert.tryagain"),
              self.$t("Alert.error_label"),
              {
                button: false,
                timer: 3000
              }
            ).then(() => {
              if (
                error?.response?.data?.message == "user not found" ||
                error?.response?.data?.message == "invalid value"
              )
                self.gosignup();
            });
          }
        }
      } else {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.pleasefill"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-signupscreen {
  background-image: url(../../assets/images/aigent_bg_07062022.png);
}

.followus > a:hover {
  border: double 1px transparent;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box-signup a:hover {
  color: var(--pink);
  text-decoration: underline;
}

.box-signup hr {
  border-color: var(--border-gray);
}

.form-control:focus {
  background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.otp-input {
  width: 75px;
  height: 75px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 50%;
  border: 1px solid #bdbdbd;
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}

.otp-input:focus {
  border: 2px solid #ee3068;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

//  form-signup > inner > input-block > input-text > form-control > int1

.form-signup .inner {
  display: block;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  margin-bottom: 15px;
}

.form-signup .input-block {
  position: relative;
  display: block;
  text-align: left;
  padding: 0;
}

.form-signup .input-block:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-top: 1px solid var(--border-gray);
  pointer-events: none;
}

.form-signup .input-block:first-child:before {
  border-top: 1px solid transparent;
}

.form-signup .input-block.focus:before {
  border: 2px solid var(--pink);
  border-radius: 10px;
}

.form-signup .input-block.focus + .input-block:before {
  border-top: 1px solid transparent;
}

.form-signup .form-control {
  background-color: transparent;
  border-radius: 0;
  font-size: var(--f-16);
  font-weight: 400;
  height: 66px;
  padding: 40px 20px 20px;
  border-left: 0;
  border-right: 0;
  border: 0;
  box-shadow: none !important;
}

.form-control:focus {
  // background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.field-icon {
  float: right;
  margin-left: -1.563rem;
  margin-top: -2.813rem;
  margin-right: 1.25rem;
  position: relative;
  z-index: 999;
}
</style>
